<template>
    <div class="login">
        <img src="../assets/image/new_return.png" alt="" class="go-back" @click="handleGoBack">
        <div class="content">
            <div class="title">欢迎登录</div>
            <div class="empty"></div>
            <div class="login-type">
                <div :class="[submitType=='user'?'active-type':'','type']" @click="handleChangeType('user')">个人账号</div>
                <div :class="[submitType=='business'?'active-type':'','type']" @click="handleChangeType('business')">
                    企业账号</div>
            </div>
            <div class="form" v-if="submitType=='user'">
                <input type="number" class="phone" placeholder="请输入您的手机号码" v-model="phone">
                <div class="code-content">
                    <input type="number" class="code" placeholder="请输入验证码" v-model="code">
                    <div class="text" @click="handleGetCode" :disabled="disabled">{{smsText}}</div>
                </div>
                <input type="text" class="phone" placeholder="请输入您的昵称" v-model="nickName" v-if="showNickName">
                <div class="explain">未注册用户将自动为您完成注册</div>
            </div>
            <div class="form" v-if="submitType=='business'">
                <input type="text" class="phone" placeholder="请输入您的用户名" v-model="userName">

                <input type="password" class="phone password" placeholder="请输入您的密码" v-model="passWord">
            </div>
            <div class="submit-btn" @click="handleSubmit">登 录</div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "login",
        data() {
            return {
                phone: "",
                code: "",
                nickName: "",
                userName: "",
                passWord: "",
                submitType: "user",
                smsText: "获取",
                disabled: true,
                showNickName: false,
                panorama_id: '',
                beforePage: false
            }
        },
        beforeRouteEnter(to, from, next) {
            if (from.name == null) {
                next(vm => {
                    vm.beforePage = true
                })
            }else{
                next()
            }
        },
        methods: {
            handleChangeType(params) {
                this.submitType = params;
                this.phone = '';
                this.code = '';
                this.nickName = "";
                this.passWord = '';
                this.userName = '';
                this.smsText = "获取";
            },
            handleGoBack() {
                if (!this.beforePage) {
                    this.$router.go(-1)
                } else {
                    this.$router.push({
                        path: "/"
                    })
                }
            },
            handleGetCode() {
                let phoneRe = /^1(3|4|5|6|7|8|9)\d{9}$/;
                if (this.disabled) {
                    if (phoneRe.test(this.phone)) {
                        this.$axios({
                            method: "POST",
                            url: "api/v5/panorama/sms",
                            data: {
                                phone: this.phone
                            }
                        }).then(res => {
                            console.log(res)
                            if (res.status == '200') {
                                let _second = 60;
                                this.smsText = `${_second}s`;
                                let _timer = setInterval(() => {
                                    _second--;
                                    if (_second === 0) {
                                        this.smsText = "获取";
                                        this.disabled = true
                                        clearInterval(_timer);
                                    } else {
                                        this.disabled = false
                                        this.smsText = `${_second}s`;
                                    }
                                }, 1000);
                                if (res.data.data.realNameStatus) {
                                    this.showNickName = false
                                } else {
                                    this.showNickName = true
                                }
                            }
                        })
                    } else {
                        alert('请填写正确的手机号')
                    }
                }

            },
            handleSubmit() {
                if (this.submitType == 'user') {
                    this.$axios({
                        method: "POST",
                        url: "api/v5/panorama/smsLogin",
                        data: {
                            phone: this.phone,
                            sms_code: this.code,
                            real_name: this.nickName,
                            panorama_id: this.panorama_id
                        }
                    }).then(res => {
                        console.log(res.data)
                        if (res.data.status == '200') {
                            sessionStorage.setItem("token", res.data.data.token)
                            sessionStorage.setItem("power", res.data.data.power)
                            sessionStorage.setItem('headImg', res.data.data.user.head_img)
                            sessionStorage.setItem('realName', res.data.data.user.real_name)
                            if (this.$route.query.resource == 'index' && res.data.data.adminLogin == true) {
                                this.$router.push({
                                    path: 'manage-index'
                                })
                            } else {
                                this.$router.go(-1)
                            }
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    })
                } else {
                    this.$axios({
                        method: "POST",
                        url: "api/v5/panorama/devLogin",
                        data: {
                            username: this.userName,
                            password: this.passWord,
                            panorama_id: this.panorama_id
                        }
                    }).then(res => {
                        if (res.data.status == '200') {
                            sessionStorage.setItem("token", res.data.data.token)
                            sessionStorage.setItem("power", res.data.data.power)
                            sessionStorage.setItem('headImg', res.data.data.user.head_img)
                            sessionStorage.setItem('realName', res.data.data.user.real_name)
                            if (this.$route.query.resource == 'index' && res.data.data.adminLogin == true) {
                                this.$router.push({
                                    path: 'manage-index'
                                })
                            } else {
                                this.$router.go(-1)
                            }
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                    })
                }
            }
        },
        created() {
            this.panorama_id = sessionStorage.getItem('panoramaId')
        },
    }
</script>

<style lang="scss" scoped>
    .login {
        width: 100vw;
        height: 100vh;
        background: url('../assets/image/bg.jpg') no-repeat;
        background-size: 100% 100%;

        .go-back {
            position: absolute;
            width: 44px;
            height: 36px;
            top: 77px;
            left: 72px;
            cursor: pointer;
            z-index: 2;
        }

        .content {
            padding: 90px 115px 95px 127px;
            position: absolute;
            width: 795px;
            height: 100vh;
            top: 0;
            right: 0;
            box-sizing: border-box;
            background: #fff;
            z-index: 2;

            .title {
                padding-bottom: 24px;
                font-size: 54px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333;
            }

            .empty {
                width: 98px;
                height: 5px;
                background: #333;
            }

            .login-type {
                display: flex;
                padding: 91px 0 43px;
                justify-content: space-between;
                align-items: center;

                .type {
                    position: relative;
                    font-size: 30px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #7E7D7E;
                    cursor: pointer;
                }

                .active-type {
                    font-weight: 600;
                    color: #000000;

                    &:before {
                        position: absolute;
                        content: "";
                        top: 50%;
                        left: -28px;
                        width: 20px;
                        height: 20px;
                        transform: translateY(-50%);
                        background: url('../assets/image/login_icon.png') no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }

            .form {
                .phone {
                    width: 100%;
                    height: 70px;
                    border: none;
                    background: #f1f1f1;
                    font-size: 20px;
                    text-indent: 1em;
                }

                .password {
                    margin-top: 20px;
                }

                .explain {
                    font-size: 20px;
                    color: #333;
                }

                .code-content {
                    margin: 20px 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .code {

                        width: 70%;
                        height: 70px;
                        border: none;
                        background: #f1f1f1;
                        font-size: 20px;
                        text-indent: 1em;
                    }

                    .text {
                        width: calc(30% - 12px);
                        height: 70px;
                        background: #000000;
                        font-size: 20px;
                        color: #fff;
                        text-align: center;
                        line-height: 70px;
                        cursor: pointer;
                    }
                }

            }

            .submit-btn {
                position: absolute;
                bottom: 95px;
                left: 127px;
                width: calc(100% - 242px);
                height: 104px;
                line-height: 104px;
                font-size: 30px;
                color: #fff;
                background: #000;
                text-align: center;
                box-shadow: 0px 0px 5px #B2B2B2;
                cursor: pointer;
            }
        }
    }
</style>